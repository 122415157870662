import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import * as styles from './ProductCard.module.css';

const ProductCard = React.forwardRef(
  ({ name, image, href, onClick, className, priority, ...rest }, ref) => {
    const gatsbyImageData = getImage(image.gatsbyImageData);

    return (
      <a
        href={href}
        onClick={onClick}
        ref={ref}
        className={classNames('block relative', className)}
        {...rest}
      >
        <div className="relative aspect-[670/940]">
          <GatsbyImage
            image={gatsbyImageData}
            alt={name}
            sizes="(max-width: 640px) calc(100vw - 32px), (max-width: 768px) 296px, (max-width: 1024px) 360px, (max-width: 1280px) 488px, (max-width: 1536px) 616px, 744px"
            // className="absolute inset-0"
          />
        </div>

        <div className="relative mt-2 z-10">
          <h3
            className={classNames(
              'font-sans font-thin uppercase text-center',
              // 'font-sans font-thin uppercase text-4xl text-center',
              styles.title
            )}
          >
            {name}
          </h3>
        </div>
      </a>
    );
  }
);

export default ProductCard;
