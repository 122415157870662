import React from 'react';
import { graphql, Link } from 'gatsby';
import { generateReadyToPlayGuitarsUrl } from '../../lib/generateReadyToPlayGuitarsUrl';
import CTA from '../components/CTA';
import ProductCard from '../components/ProductCard';
import ProductsGrid from '../components/ProductsGrid';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import MediaText from '../components/MediaText';
import PageHeader from '../components/PageHeader';

const translations = [
  {
    locale: 'fr',
    url: '/guitares-pretes-a-jouer/',
  },
];

export default function ReadyToPlayGuitarsPage({ data }) {
  let {
    guitars: { nodes: guitars = [] },
  } = data;

  guitars = guitars.map((guitar) => ({
    name: guitar.name,
    image: {
      gatsbyImageData: guitar.featuredImage.asset.localFile.childImageSharp,
    },
    href: generateReadyToPlayGuitarsUrl('en', guitar.slug.current),
  }));

  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>
          Ready-to-play <br />
          guitars
        </PageHeader>

        <MediaText left top media={data.readyToPlayImage}>
          <div className="max-w-prose">
            <Heading level={2} className="!mt-0">
              A selection of high-end guitars
            </Heading>

            <p>
              With a glass of scotch in hand, you marvel at the work of art on
              the wall. Look again! It’s not a painting; better yet, it’s a
              guitar. And not just any old guitar: it’s a Pellerin guitar,
              begging to be played. You take it down and place your fingers. The
              sound is just perfect! And you have so much fun playing it that
              your other guitars are getting jealous.
            </p>
          </div>
        </MediaText>

        <div className="container text-xl text-center">
          <Heading level={2} className="text-5xl">
            Pellerin guitars for sale
          </Heading>

          <p className="max-w-prose mx-auto">
            Michel Pellerin often builds two almost identical guitars at a time,
            changing only the type of wood. So why not buy the second one? You
            get the same quality as a custom-made guitar, yet it is delivered
            much faster. Is that not music to your ears?
          </p>
        </div>

        <section className="container">
          <ProductsGrid>
            {guitars.map((guitar) => (
              <ProductCard
                key={guitar.href}
                name={guitar.name}
                href={guitar.href}
                image={guitar.image}
              />
            ))}
          </ProductsGrid>
        </section>

        <CTA>
          <div className="max-w-prose">
            <Heading level={2} className="!mt-0">
              You are looking for a custom guitar?
            </Heading>

            <Link
              to="/en/custom-made-guitars/"
              className="underline font-medium text-xl"
            >
              Discover the whole process
            </Link>
          </div>
        </CTA>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    readyToPlayImage: file(name: { eq: "PellerinGuitares_011" }) {
      childImageSharp {
        gatsbyImageData(width: 1536, placeholder: BLURRED)
      }
    }

    guitars: allSanityReadyToPlay(sort: { fields: orderRank, order: ASC }) {
      nodes {
        slug {
          current
        }
        name
        featuredImage {
          asset {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 744, height: 1044, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;
