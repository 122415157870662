import React from 'react';
import classNames from 'classnames';
import { m as motion } from 'framer-motion';

const CardContainer = ({ children, index }) => {
  const ref = React.useRef(null);

  return <motion.div ref={ref}>{React.cloneElement(children, {})}</motion.div>;
};

const ProductsGrid = ({ children, className = '' }) => {
  return (
    <div
      className={classNames(
        'grid sm:grid-cols-2 gap-12 items-start',
        className
      )}
    >
      {children.map((child, index) => (
        <CardContainer key={index} index={index} children={child} />
      ))}
    </div>
  );
};

export default ProductsGrid;
